import { timeFormat, numberFormat } from "@custom/index";
export function apartmentColumn(_this) {
    return [
        {
            type: "selection",
            width: 55,
            // fixed: 'left'
        },
        {
            type: "index",
            label: "序号",
            width: 80,
            // fixed: 'left'
        },
        {
            prop: "name",
            label: "房源",
            // fixed: 'left',
            width: 250,
            render: (h, {row}) => {
                const {name} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            prop: "statusName",
            label: "出租状态",
        },
        {
            prop: "isLetOutRentName",
            label: "对外情况",
        },
        {
            prop: "publishStatusName",
            label: "发布情况",
        },
        {
            prop: "propertyCode",
            label: "房源类型",
            width: 200,
            // fixed: 'left',
            render: (h, { row }) => {
                const propertyCode =_this.propertyCodeList.find(v => v.code === row.propertyCode);
                // 2024/02/27   张晓瑜需改保障性住房为保障性租赁住房(暂时)
                const codeName = propertyCode ? ((propertyCode.code.toString()).startsWith("60021")?"保障性租赁住房:":"长租公寓:")+ propertyCode.codeName : "--";
                return h("span", (codeName));
            },
        },
        // {
        //     prop: "apartmentType",
        //     label: "户型",
        //     width: 100
        // },
        {
            prop: "updateTime",
            label: "发布时间",
            width: 100,
            render: (h, { row }) => {
                return timeFormat(row.updateTime?new Date(row.updateTime):"");
            }
        },
        {
            prop: "pushAmount",
            label: "发布金额(元）",
            width: 100,
            render: (h, {row}) => {
                const {pushAmount} = row;
                return h("span", pushAmount ? numberFormat(pushAmount / 100) : '');
            }
        },
        {
            prop: "pushStatus",
            label: "是否推送住建平台",
            width: 190,
            render: (h, { row }) => {
                let {pushStatus} = row;
                let pushStatusName = "";

                if(pushStatus === '1') pushStatusName = "是";
                if(pushStatus === '0') pushStatusName = "否";
                return pushStatusName;
            }
        },
        {
            prop: "pushResult",
            label: "推送结果",
            width: 100
        },
        /*{
            prop: "publishArea",
            label: "面积",
            render: (h, { row }) => {
                return h("span", row.publishArea?row.publishArea+"㎡":"0");
            },
            width: 100
        },*/
    ];
}

export function apartmentColumndailog(_this) {
    return [

        {
            type: "index",
            label: "序号",
            width: 70,
            // fixed: 'left'
        },
        {
            prop: "name",
            label: "房源",
            // fixed: 'left',
            width: 180,
        },

        {
            prop: "tipAmount",
            label: "服务费(元/月)",
            width: 100,
            render: (h, {row}) => {
                const {tipAmount} = row;
                return h("span", tipAmount ? numberFormat(tipAmount / 100) : "0");
            }

        },
        {
            prop: "yearAmount",
            label: "单价(元)",
            render: (h, {row}) => {
                const {monthPriceAmount} = row;
                return h("span", monthPriceAmount ? numberFormat(monthPriceAmount / 100) : "");
            }
        },
        {
            prop: "outsideArea",
            label: "面积(㎡)",
        },
        {
            prop: "sixMonthDiscount",
            label: "半年租金折扣",
            width: 100,
            render: (h, {row}) => {
                const {sixMonthDiscount} = row;
                return h("span", sixMonthDiscount ? sixMonthDiscount : "100");
            }
        },
        {
            prop: "halfRentAmount",
            label: "半年租金（元)",
            width: 100,
            render: (h, {row}) => {
                const {halfRentAmount} = row;
                return h("span", halfRentAmount ? numberFormat(halfRentAmount / 100) : "");
            }
        },
        {
            prop: "yearAmount",
            label: "半年押金（元）",
            width: 100,
            render: (h, {row}) => {
                const {halfBoneAmount} = row;
                return h("span", halfBoneAmount ? numberFormat(halfBoneAmount / 100) : "");
            }
        },
        {
            prop: "yearDiscount",
            label: "年租金折扣",
            render: (h, {row}) => {
                const {yearDiscount} = row;
                return h("span", yearDiscount ? yearDiscount : "100");
            }
        },
        {
            prop: "yearRentAmount",
            label: "年租金（元)",
            width: 100,
            render: (h, {row}) => {
                const {yearRentAmount} = row;
                return h("span", yearRentAmount ? numberFormat(yearRentAmount / 100) : "");
            }
        },
        {
            prop: "yearBoneAmount",
            label: "年押金（元）",
            width: 100,
            render: (h, {row}) => {
                const {yearBoneAmount} = row;
                return h("span", yearBoneAmount ? numberFormat(yearBoneAmount / 100) : "");
            }
        },
    ];
}
