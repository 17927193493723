<template>
  <r-e-dialog :title="title" class="layer-contract-detail" :footerBtnName="footerBtnName" :visible.sync="dialogVisible" show-footer top="10vh" width="1000px"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <r-e-table :showPagination="false" class="bg-white" ref="roleTableRef" :dataRequest="getApartmentTableData" :columns="apartmentColumn" :query="formSearch" :height="440">
    </r-e-table>
    <el-form v-if="title=== '发布'" :model="ruleForm" size="mini" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="保租房项目" >
        <el-select v-model="ruleForm.projectId" placeholder="请选择" filterable >
          <el-option v-for="item in projectList" :key="item.id" :label="item.project_name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="住建平台" prop="verificationCode">
        <el-radio-group v-model="ruleForm.verificationCode">
          <el-radio :label="true">推送</el-radio>
          <el-radio :label="false">不推送</el-radio>
        </el-radio-group>
      </el-form-item>
      <div style="color: red;margin-left: 100px;margin-top: 1px;margin-bottom: 10px">当选择推送至住建平台，仅具备有住建房源核验码才可推送</div>
      <el-form-item label=""  v-if="title=== '发布'">
        <el-button @click="CancledApartment" style="margin-left: -80px" type="primary">计算金额</el-button>
<!--        http://mstsc.neoyon.com:8090/web/#/17/2473      因为灵活用工以及后台接口速度原因-暂时取消编辑-->
      </el-form-item>
    </el-form>

    <el-alert
        v-if="title=== '取消'"
        type="warning"
        style="background-color:#ffffff;"
        :closable="false"
        show-icon>
        <span style="color: black">请确认是否取消发布以上房源</span>
      </el-alert>
  </r-e-dialog>
</template>

<script>
import {czhiputApi, getBzfProject} from "@/api/house-resource";
import {getRoleList} from "@/api/role";
import {tableColumn} from "@/views/system-management/role-management/data";
import {apartmentColumndailog} from "@/views/rental-management/publish-apartment/data";
import {
  getApartmentByPublishStatus, getBatchPublishApartment,
  getPublishListByisRent, getPublishListgetBzfProject, getPublishListgetBzfProjectList,
  toBeReleasedOrCancledApartmentCalculateAmount
} from "@/api/publish";
// import UploadPictureCard from "@/components/Upload/upload-picture-card.vue";
export default {
  name: "",
  props:{
    multipleSelection: Array,
  },
  components: {},
  data(){
    return {
      dialogVisible: false,

      footerBtnName:['取消', '确定'],
      apartmentColumn:apartmentColumndailog(this),
      formSearch:{},
      apartmentUuids:[],
      roleTableReflist:[],
      projectList: [],
      title:'发布',
      ruleForm: {
        projectId:'',
        verificationCode: '',
      },
      rules:{
        verificationCode:[ { required: true, message: '不可为空', trigger: 'blur' }]
      }
      // .toLocaleString()
    };
  },
  mounted() {

  },
  methods:{
   async getApartmentTableData(params) {
      const {msg} = await getBzfProject();
      const {result} =  JSON.parse(msg)
      console.log(this.apartmentUuids);
      this.projectList = result;
     let arr =   this.apartmentUuids.map(item => {
       return {sixMonthDiscountStr:'100',yearDiscountStr:'100',...item}
     })
      params.apartmentUuids = this.apartmentUuids
     // if(this.tableitr){
     //   arr = this.roleTableReflist.map(item => {
     //
     //     return {sixMonthDiscountStr:'100',yearDiscountStr:'100',...item}
     //   })
     // }
     console.log(arr)
      return getPublishListByisRent(arr);
    },
    CancledApartment(){
      console.log(this.$refs["roleTableRef"].data)
      this.apartmentUuids = this.$refs["roleTableRef"].tableData

      console.log(this.apartmentUuids)
      this.$refs["roleTableRef"].getTableData();
      this.getApartmentTableData()
      // this.$refs.communityTableRef.getTableData();
      // this.$refs.apartmentTableRef.getTableData(isSearch);
    },
    // 照片成功
    enterpriseClick(row){
      console.log(row)
    },
    clickSubmit(){

      if(this.title === '取消'){
        let loadingOptions = this.loadingOptions;
        const loading = this.$loading({...loadingOptions});
        let arr = this.apartmentUuids.map(item => item.apartmentUuid)
        getPublishListgetBzfProjectList({uuid:arr}).then(res => {
          loading.close()
          this.dialogVisible = false
          this.$emit('handleChange')
          this.$message.success(res.msg);
        })
        return
      }
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          let loadingOptions = this.loadingOptions;
          const loading = this.$loading({...loadingOptions});
     if(this.title === '发布'){
       let arr = this.apartmentUuids.map(item => item.apartmentUuid)
       getPublishListgetBzfProject({apartmentUuids:arr,projectId:this.ruleForm.projectId,}).then(res => {
         console.log(res)
       })
       //调用接口保存
       let ByisRentarr =   this.apartmentUuids.map(item => {
         return {sixMonthDiscountStr:'100',yearDiscountStr:'100',...item}
       })
       let apartmentUuidsarr =   await getPublishListByisRent(ByisRentarr)
       apartmentUuidsarr.list.map(item => {
         item.projectId = this.ruleForm.projectId
         item.tipAmount = item.monthPriceAmount
         item.verificationCode = this.ruleForm.verificationCode
       })
       console.log(apartmentUuidsarr,'apartmentUuidsarr')
       getBatchPublishApartment(apartmentUuidsarr.list).then(res => {
         loading.close()
         this.dialogVisible = false
         this.$emit('handleChange')
         this.$message.success(res.msg);
       })
     }

          // czhiputApi(uuid,{ ...this.ruleForm,amount:myamount}).then(res => {
          //   console.log(res)
          //   this.dialogVisible = false
          //   max = ''
          //   this.ruleForm = {
          //     type: '',
          //     amount: '',
          //     paymentMethod: '',
          //   }
          //
          //   this.$message.success(res.msg);
          //   // this.$parent.getDeposit()
          //   this.$emit('getDeposit');
          // }).catch(err => {
          //   this.$message.error('请求失败请联系管理员！');
          // })
        } else {
          this.$message.error('请完善表单相关信息！');
          return false;
        }
      })
    },
    clickCancel(){
      // alert(2)
      this.dialogVisible = false
    },

  },

}
</script>

<style scoped>

</style>
